(function () {
	'use strict';
	angular
		.module('app')
		.directive('debugMode', [
			'seedcodeCalendar',
			'utilities',
			'dataStore',
			'firebaseIO',
			debugMode,
		]);

	function debugMode(seedcodeCalendar, utilities, dataStore, firebaseIO) {
		return {
			restrict: 'EA',
			// scope: true,
			link: function (scope, element, attrs) {
				let debugMode = dataStore.getState(
					'debugMode',
					false,
					false,
					true
				);

				scope.isDebug = debugMode ? true : false;

				scope.toggle = toggle;

				/** @type {() => void} */
				function toggle() {
					if (scope.isDebug) {
						debugMode = utilities.generateUID();
						createDebugData(debugMode);
					} else {
						toggleOff(() => {
							clearDebugData(debugMode);
							debugMode = null;
						});
					}
				}

				/** @type {(id: string) => void} */
				async function createDebugData(id) {
					// call backend migration functions to migrate settings
					const groupData = await firebaseIO.getGroupData('');
					const userData = await firebaseIO.getUserData(null, '');
					const userSettingsData =
						await firebaseIO.getUserSettingsData(null, '');
					seedcodeCalendar.init('debugMode', id);
					await firebaseIO.setGroupData(null, null, groupData);
					await firebaseIO.setUserData(null, null, null, userData);
					await firebaseIO.setUserSettingsData(
						null,
						null,
						null,
						userSettingsData
					);
					dataStore.saveState('debugMode', id, false, false, true);
				}

				/** @type {(callback: function) => void} */
				function toggleOff(callback) {
					const modalTitle = 'Publish Changes';
					const modalMessage =
						'Would you like to publish your changes to settings?';
					utilities.showModal(
						modalTitle,
						modalMessage,
						'Discard',
						callback,
						'Save Snapshot',
						null,
						'Publish',
						() => {
							publishDebugData(callback);
						}
					);
				}

				/** @type {(callback: function) => Promise} */
				async function publishDebugData(callback) {}

				/** @type {(id: string) => void} */
				async function clearDebugData(id) {
					seedcodeCalendar.init('debugMode', null);
					await firebaseIO.clearDebug(id);
					dataStore.clearState(
						'debugMode',
						false,
						false,
						false,
						true
					);
				}

				function applyDebugChanges() {}
			},
			template: `<div
					style="
						display: flex;
						align-items: center;
						margin-bottom: 5px;
					"
				>
					<label translate style="margin: 0px">Settings Draft Mode</label>
					<div style="height: 28px; margin: 0 8px">
						<div class="switch vertical-center">
							<input
								id="toggle-switch-debug-mode"
								ng-model="isDebug"
								ng-change="toggle()"
								class="cmn-toggle cmn-toggle-round"
								type="checkbox"
							/>
							<label
								for="toggle-switch-debug-mode"
							></label>
						</div>
					</div>
					<button
						ng-click="help('ShowCalendarAsResource', '83-show-calendar-as-resource')"
						class="btn btn-link btn-help btn-help-inline"
						style="margin: 0px"
					>
						<i
							class="fa fa-question-circle fa-lg dbk_icon_help"
						></i>
					</button>
				</div>`,
		};
	}
})();
